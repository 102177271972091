<template>
  <div>
    <div class="knowledgebase-container">
      <div
        class="ticket-overlay"
        @click="isVisible = !isVisible"
        v-show="isVisible"
      ></div>
      <div class="sidebar is-mobile" :class="isVisible ? 'visible' : ''">
        <div class="box_inside_container">
          <div class="box_inside">
            <div class="box_content">
              <div
                @click="$router.push({ name: 'knowledgeBase' })"
                class="box_item"
              >
                <strong>< {{ $t("Back to list") }}</strong>
              </div>
              <div
                v-for="(item, i) in pageData"
                @click="
                  $router.push({ path: '/knowledge-base/' + item.pageId })
                "
                class="box_item"
                :class="item.pageId == pageId ? 'selected' : ''"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row :gutters="3" pa-2>
        <v-col style="max-width: 300px !important" class="is-desktop">
          <div class="sidebar" :class="isVisible ? 'visible' : ''">
            <div class="box_inside_container">
              <div class="box_inside">
                <div class="box_content">
                  <div
                    v-for="(item, i) in pageData"
                    @click="
                      $router.push({ path: '/knowledge-base/' + item.pageId })
                    "
                    class="box_item"
                    :class="item.pageId == pageId ? 'selected' : ''"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="content_page">
            <div class="content_header">
              <a @click="isVisible = !isVisible" class="is-mobile"> < </a>
              <a
                @click="$router.push({ name: 'knowledgeBase' })"
                class="is-desktop"
                ><
              </a>
              {{ ptitle ? ptitle.title : "" }}
              <!--   {{ content.title }}    -->
            </div>
            <div class="content_html" v-html="content"></div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script  >
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "KnowledgeBaseDetail",

  data() {
    return {
      content: "",
      isVisible: false,
      mainCategories: [],
      ptitle: {},
    };
  },
  created() {
    this.starter();
  },
  computed: {
    pageId() {
      return this.$route.params.id;
    },
    pageData() {
      return this.$store.state.common.pages;
    },
  },
  watch: {
    pageId(val) {
      this.starter();
    },
    pageData() {
      let pageId = this.pageId;
      this.ptitle = this.pageData.find((k) => k.pageId == pageId);
    },
  },
  methods: {
    starter() {
      this.page();
      this.pages();
    },
    async pages() {
      let pid = this.pageId;
      await this.$store.dispatch("common/knowledgebasePages", { pid });
    },
    async page() {
      // var xhr = new XMLHttpRequest();
      let uid = this.$store.state.common.main.uid;
      let pageId = this.pageId;
      let kb = this.$store.state.common.main.kb_collection_tr;
      let url =
        "https://storage.googleapis.com/kiva.support/85fb2fcf-3b28-11eb-a87c-c2943e4e42ad/pages/collection" +
        kb +
        "/page" +
        pageId +
        ".html";

      let response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });
      this.content = await response.text();
    },
  },
};
</script>
<style lang="scss" scoped>
.knowledgebase-container {
  background: #f6f8fc;
  width: 100%;
  padding: 20px;
  .content_page {
    background: #fff;
    border-radius: 7px;
  }
  .box_inside_container {
    width: 100%;
    position: relative;

    .box_inside {
      height: calc(100vh - 120px);
      background: #ffffff;
      align-items: center;
      padding: 5px 0px;
      border: 1px solid #d9e1ee;
      box-sizing: border-box;
      border-radius: 5px;
      width: 285px;
      position: fixed;
      .box_header {
        border-bottom: 1px solid #d9e1ee;
        width: 100%;
        padding: 10px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #465669;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .box_content {
        // padding-bottom: 20px;
        padding: 10px;
      }
      .box_item {
        padding: 5px 0px;
        border-bottom: 0.5px dashed #d9e1ee;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        width: 100%;
        &.selected {
          font-weight: 600;
        }
        &.last {
          border: 0;
          cursor: pointer;
          text-align: right;
          color: #2887eb;
        }
      }
      .box_not_visible {
        display: none;
      }
    }
  }
  .content_header {
    border-bottom: 1px dashed #d9e1ee;
    padding: 20px 10px;
    color: #465669;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    a {
      border-radius: 50%;
      background: #f6f6f6;
      width: 35px;
      height: 35px;
      text-align: center;
      padding-top: 7px;
      display: inline-block!important;
    }
  }
  .content_html {
    color: #465669;
    padding: 15px;
  }
}



</style>
<style >
@media screen and (min-width: 801px) {
.knowledgebase-container   .content_header   a.is-mobile { display: none!important;}
  .knowledgebase-container   .content_header   a.is-desktop {  display: inline-block!important;}
}
@media screen and (max-width: 800px) {
  .knowledgebase-container   .content_header   a.is-desktop { display: none!important;}
.knowledgebase-container   .content_header   a.is-mobile {  display: inline-block!important;}
}
</style>